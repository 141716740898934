<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import Teams from '@/services/Teams'
    import validationMessages from '@/components/validations'
    import vue2Dropzone from "vue2-dropzone";
    import DatePicker from "vue2-datepicker";

    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import "vue-multiselect/dist/vue-multiselect.min.css";
    import Swal from "sweetalert2";
    import moment from "moment";
    import General from "@/services/General";

    export default {
        components:{
            vueDropzone: vue2Dropzone,
            validationMessages, DatePicker
        },
        props: {
            squadData: {
                type: Object,
            },
            squadProvider :{
                type: Array,
                default: null
            } ,
          squad_func: {
            type: Array,  // Change from Object to Array
            default: () => []
          }
        },
        data() {
            return {
                additional_squad_images: process.env.VUE_APP_ADDITIONAL_SQUAD_IMAGES,
                csrf_token: localStorage.getItem('csrf_token'),
                id_e: '',
                first_name_e: '',
                biographyData: { biography: '', ai_generated: 0 },
                squadId: '',
                date_of_birth : '' ,
                selectedProvider: null,
                displaySourceData : null ,
                provider_first_name : '' ,
                provider_last_name : '' ,
                provider_shirt_number : '' ,
                provider_source_id : '' ,
                last_name_e: '',
                matchingRow : null ,
                shirt_number_e: '',
                birthdate_e: '',
                defaultShow: true,
                clickedDate: '',
                player_country_code_e: '',
                city_e: '',
                countries: '',
                function_e: '',
                order_e: '',
                person_id_e: '',
                img_e: '',
                file_e: '',
                twitter_e: '',
                instagram_e: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                selected_func: null,
                file_additional:[],
                singleImage: false,
                lightboxVisible: [],
                lightboxImages: [],
                show_add_image:false,
                activeTab: 'Profile',
                show_add_image_primary:false,
                dropzoneOptions: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*'
                },
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*',
                    maxFiles: 2,
                },
                loadingBiography: false,
                tenant: process.env.VUE_APP_TENANT,
            };
        },
        validations: {
            first_name_e: {
                required,
                minLength: minLength(2)
            },
            last_name_e: {
                required,
                minLength: minLength(2)
            },
        //    function_e:{
        //        required
        //    },
            order_e: {
                numeric,
            },
        },
      computed: {
        formattedDate() {
          if (this.squadData.item.unix_timestamp) {
            // Convert the Unix timestamp to a Moment.js object
            let date = moment.unix(this.squadData.item.unix_timestamp);
            // Format the date as DD.MM.YYYY HH:mm
            return date.format('DD.MM.YYYY HH:mm');
          }
          return '';
        }
      },

        methods: {


            openLightbox(index) {
                this.lightboxVisible = [];
                this.lightboxVisible[index] = true;
            },
            closeLightbox(index) {
                this.lightboxVisible = [];
                this.lightboxVisible[index] = false;
            },

            refreshData() {
                this.$emit('onRefresh')
            },
            async getCountry() {
              try {
                await General.getCountry({

                }).then((response) => {
                  this.countries = this.preferredCountryOrder(response.data, 'name');
                }).catch(error => {
                  this.error = error.response.data.error ? error.response.data.error : "";
                  this.failedmsg("Can not get countries")
                }).finally(() => {
                })

              } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg("Can not get countries")
              }
            },
             async getSquadBiography(data) {
                 this.loadingBiography = true;
                 try {
                     const response = await Teams.getSquadBiography("squad_id=" + data);
                     if (response.data && response.data.length > 0) {
                         this.biographyData = response.data[0];
                         this.ai_generated = this.biographyData.ai_generated;
                         this.squadId = this.biographyData.squad_id;
                     } else {
                         this.biographyData = { biography: '', ai_generated: 0 };
                     }
                 } catch (error) {
                     this.error = error.response.data.error ? error.response.data.error : "";
                 }finally {
                     this.loadingBiography = false;
                 }
             },
          async editSquadPlayer() {
            this.tryingToEdit = true;
            this.submitted = true;
            this.$v.$touch()

            if (this.$v.$invalid) {
              this.tryingToEdit = false;
              return;
            } else {
              try {
                let formData = new FormData();
                formData.append('first_name', this.first_name_e);
                formData.append('last_name', this.last_name_e);

                const selectedFunction = this.squad_func.find(sfunc => sfunc.function_code === this.function_e);
                if (selectedFunction) {
                  formData.append('function', selectedFunction.function_title);
                } else {
                  formData.append('function', '');
                }
                if (this.file_e) {
                  formData.append('file', this.file_e);
                }
                formData.append('shirt_number', this.shirt_number_e);
                if (this.birthdate_e === "Invalid Date") {
                  formData.append('birthdate', this.birthdate_e);
                }
                formData.append('country_code', this.player_country_code_e);
                formData.append('city', this.city_e);
                formData.append('order', this.order_e);
                formData.append('instagram', this.instagram_e);
                formData.append('twitter', this.twitter_e);
                formData.append('csrf_token', this.csrf_token);
                formData.append('_method', 'PATCH');

                if (!this.file_e) {
                  formData.append('img', this.img_e)
                }

                if (this.file_additional) {
                  this.file_additional.forEach((file) => {
                    formData.append('file_additional[]', file);
                  });
                }

                await Teams.editSquadPlayer(this.id_e, formData)
                    .then(async (response) => {
//                      await this.editSquadBiography({
//                        squad_id: this.id_e,
//                        biography: this.biographyData.biography,
//                      });

                      this.successmsg("Changes have been updated");
                      this.closeModal();

                    }).catch(error => {
                      this.error = error.response.data.error ? error.response.data.error : "";
                      this.failedmsg(this.error);
                    }).finally(() => {
                      this.refreshData();
                      this.tryingToEdit = false;
                    });

              } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg(this.error);
              }
            }
            this.tryingToEdit = false;
          },

          async editSquadBiography(data) {
            return Teams.editSquadBiography(data)
                .catch((error) => {
                  this.error = error.response.data.error || "Failed to edit squad biography.";
                  this.failedmsg(this.error);
                });
          },
            async saveBiographyOnly() {
                try {
                    await this.editSquadBiography({
                        squad_id: this.id_e,
                        biography: this.biographyData.biography,
                    });
                    this.successmsg("Biography updated successfully");
                } catch (error) {
                    this.failedmsg(this.error || "Failed to update biography.");
                }
            },
          selectFile(files){
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image')

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary.removeFile(value)
                        });
                    }

                    this.file_e = queuedFiles[0];
                }, 250);
            },

            selectFile_additional(file){
                setTimeout(() => {
                    let queuedFiles_2 = this.$refs.myVueDropzone.getQueuedFiles();
                    this.file_additional = queuedFiles_2
                }, 250);
            },

            deleteAdditionalImage(id){

                Swal.fire({
                    title: "Remove Image?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            this.tryingToEdit = true
                            Teams.removeSquadAdditionalImage(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        Swal.fire("Remove image from player", "Image successfully removed!", "success");
                                        var foundIndex = this.squadData.item.additional_images.findIndex(x => x.id == id);
                                        this.squadData.item.additional_images.splice(foundIndex, 1);
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                    this.tryingToEdit = false
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },

            deleteSquadPrimaryImage(id){
                Swal.fire({
                    title: "Remove Image?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            this.tryingToEdit = true
                            Teams.deleteSquadPrimaryImage(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        Swal.fire("Remove image from player", "Image successfully removed!", "success");

                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                    this.tryingToEdit = false;
                                    this.closeModal();
                                    this.refreshData()


                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },
            

            closeModal() {
                this.showModal = false;
                this.show_add_image = false;
                this.show_add_image_primary = false;
                this.file_additional = [];
            },

            syncData(){
                this.getCountry();
                this.id_e = this.squadData.item.id;
                this.first_name_e = this.squadData.item.first_name;
                this.last_name_e = this.squadData.item.last_name;
                this.shirt_number_e = this.squadData.item.shirt_number;
                this.img_e = this.squadData.item.img;
                this.order_e = this.squadData.item.order;
                this.person_id_e = this.squadData.item.person_id;
                this.function_e = this.squadData.item.function_code;
                this.birthdate_e = new Date(this.squadData.item.birthdate);
                this.player_country_code_e = this.squadData.item.nation_code;
                this.city_e = this.squadData.item.city;
                this.instagram_e = this.squadData.item.instagram;
                this.twitter_e = this.squadData.item.twitter;
                this.squad_imported = this.squadProvider.squad_imported;

                const providerItem= this.squadProvider;

                const personId = this.squadData.item.person_id;

                // Find the matching row in squadProvider array
                const matchingRow = this.squadProvider.find(providerItem => providerItem.player_id === personId);

                // this.biographyData = { biography: '', ai_generated: 0 };
                this.aiGenerated = '';
                this.squadId = '';
                this.getSquadBiography(this.id_e);

                if (matchingRow) {
                 this.displaySourceData = true;
                    this.provider_first_name = matchingRow.first_name
                    this.provider_last_name = matchingRow.last_name
                    this.provider_shirt_number = matchingRow.shirt_number
                    this.provider_source_id = matchingRow.player_id

                } else {
                   this.displaySourceData = false  ;
                }
//                if(this.additional_squad_images == 'true'){
                    if(this.squadData.item.additional_images.length > 0){
                        this.lightboxImages = this.squadData.item.additional_images.map(img => img.img_url);
                    }
//                    if (this.squadData.item && this.squadData.item.additional_images && this.squadData.item.additional_images.length > 0) {
//                        this.lightboxImages = this.squadData.item.additional_images.map((img) => img.img_url);
//                    } else {
//                        this.lightboxImages = [];
//                    }
//                }

            }

        }
    };
</script>


<template>
    <b-modal @shown="syncData" v-model="showModal" id="squad_edit" title="Edit Squad Player" title-class="font-18" centered>

        <form @submit.prevent="editSquadPlayer">
            <div class="alert alert-danger" v-if="displaySourceData === true">
                    <label>Source Player data:</label>
                    <div> {{provider_first_name}}  {{provider_last_name }} {{provider_shirt_number }} </div>
                </div>
            <div v-else></div>
            <div class="row">
                <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
                    <b-tab title="Profile" active title-item-class="mb-2" @click="activeTab = 'Profile'">
                        <b-card-text>
                            <b-form-group label="Firstname" label-for="formrow-firstname-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="first_name_e"
                                        type="text"
                                        id="firstname"
                                        aria-describedby="firstname-feedback"
                                        :class="{
                                          'is-invalid': submitted && $v.first_name_e.$error,
                                        }"
                                >
                                </b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.first_name_e"></validationMessages>
                            </b-form-group>

                            <b-form-group label="Lastname" label-for="formrow-lastname-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="last_name_e"
                                        type="text"
                                        id="lastname"
                                        aria-describedby="lastname-feedback"
                                        :class="{
                                          'is-invalid': submitted && $v.last_name_e.$error,
                                        }"
                                >
                                </b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.last_name_e"></validationMessages>
                            </b-form-group>

                          <b-form-group v-if="tenant != 'HHC'" label="Function" label-for="formrow-function-input" class="mb-3">
                            <select v-model="function_e" class="form-select">
                              <option v-for="sfunc in squad_func" :key="sfunc.function_name" :value="sfunc.function_code">{{sfunc.function_title}}</option>
                            </select>
                          </b-form-group>



                          <b-form-group label="Shirt Number" label-for="formrow-shirt_number-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="shirt_number_e"
                                        type="text"
                                        id="shirt_number"
                                        aria-describedby="shirt_number-feedback"
                                >
                                </b-form-input>
                            </b-form-group>

                          <b-form-group label="Birthdate" name="birthdate_e" label-for="formrow-birthdate-input" class="mb-3">
                            <date-picker
                                v-model="birthdate_e"
                                append-to-body
                                lang="en"
                                confirm
                                format="DD/MM/YYYY"
                            ></date-picker>
                          </b-form-group>
                          <b-form-group label="Country" label-for="formrow-country-input" class="mb-3">
                            <select v-model="player_country_code_e" class="form-select">
                              <option v-for="country in countries" :key="country.name" :value="country.alpha3code">{{country.name}}</option>
                            </select>
                          </b-form-group>

                          <b-form-group label="City" label-for="formrow-city-input" class="mb-3">
                            <b-form-input
                                v-model.trim="city_e"
                                type="text"
                                id="instagram"
                                aria-describedby="twitter-feedback"
                            >
                            </b-form-input>
                          </b-form-group>
                            <b-form-group label="Order" label-for="formrow-order-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="order_e"
                                        type="number"
                                        min="1"
                                        id="order"
                                        aria-describedby="order-feedback"
                                        :class="{
                          'is-invalid': submitted && $v.order_e.$error,
                        }"
                                >
                                </b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'Order'" :validationName="$v.order_e"></validationMessages>
                            </b-form-group>

                            <b-form-group label="Instagram" label-for="formrow-instagram-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="instagram_e"
                                        type="text"
                                        id="instagram"
                                        aria-describedby="twitter-feedback"
                                >
                                </b-form-input>
                            </b-form-group>

                            <b-form-group label="Twitter" label-for="formrow-twitter-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="twitter_e"
                                        type="text"
                                        id="twitter"
                                        aria-describedby="twitter-feedback"
                                >
                                </b-form-input>
                            </b-form-group>
                            <input type="hidden" name="csrf_token" v-model="csrf_token">
                        </b-card-text>
                    </b-tab>

                    <b-tab title="Images" title-item-class="mb-2" @click="activeTab = 'Images'">
                        <b-card-text class="text-center">
                            <div class="row mb-3">
                                <div class="col-12 mb-3">
                                    <h5 class="text-center">Primary Image</h5>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-sm" @click="show_add_image_primary = !show_add_image_primary">{{show_add_image_primary ? 'View Image' : 'Change Primary Image'}}</a>
                                </div>

                                <div class="col-12">
                                    <template v-if="!show_add_image_primary">
                                        <b-form-group label-for="image" class="mb-3 text-center image-area">
                                            <img :src="img_e != '' ? img_e : require('@/assets/images/image_not_found.png')" alt="" width="200" class="img-thumbnail" >
                                        </b-form-group>
                                        <a  v-if="img_e != ''" class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteSquadPrimaryImage(id_e)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </template>
                                    <template v-else>

                                        <vue-dropzone
                                                id="dropzone_primary"
                                                ref="myVueDropzone_primary"
                                                :use-custom-slot="true"
                                                :options="dropzoneOptions_primary"
                                                @vdropzone-files-added="selectFile"
                                                no-progress-bar
                                        >
                                            <div class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <h4>Drop file here or click to upload.</h4>
                                            </div>
                                        </vue-dropzone>
                                    </template>
                                </div>
                            </div>

                            <div class="row mt-3" v-if="additional_squad_images == 'true'">
                                <div class="col-12 mt-3 mb-3">
                                    <h5>Additional Images</h5>
                                    <a href="javascript:void(0);" class="btn btn-primary btn-sm" @click="show_add_image = !show_add_image">{{show_add_image ? 'View Images' : 'Add Images'}}</a>
                                </div>

                                <template v-if="!show_add_image && squadData.item">
                                    <div class="col-4 d-flex justify-content-center popup-gallery mb-1" v-for="(img_a, index) in squadData.item.additional_images" :key="index">
                                        <div class="d-flex flex-column align-items-center">
                                            <img class="align-self-center mt-auto" :src="img_a.img_url" alt="" width="120" @click="openLightbox(index)">
                                            <a  class="btn text-danger" v-b-tooltip.hover title="Delete" @click="deleteAdditionalImage(img_a.id)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </div>
                                        <vue-easy-lightbox
                                                :visible="lightboxVisible[index]"
                                                :imgs="lightboxImages"
                                                @hide="closeLightbox(index)"
                                        ></vue-easy-lightbox>
                                    </div>
                                    <div class="col-12" v-if="squadData.item.additional_images && !squadData.item.additional_images.length">
                                        <p class="text-center">No additional images</p>
                                    </div>
                                </template>

                                <template v-else >
                                    <div class="col-12 mt-3">
                                        <vue-dropzone
                                                id="dropzone"
                                                ref="myVueDropzone"
                                                :use-custom-slot="true"
                                                :options="dropzoneOptions"
                                                @vdropzone-files-added="selectFile_additional"
                                                no-progress-bar
                                        >
                                            <div class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </vue-dropzone>
                                    </div>
                                </template>
                            </div>

                        </b-card-text>
                    </b-tab>
                    <b-tab title="Player BIO" title-item-class="mb-2" @click="activeTab = 'Player BIO'">
                        <b-card-text>
                            <template v-if="loadingBiography">
                                <div class="text-center my-3">
                                    <b-spinner label="Loading..." />
                                </div>
                            </template>
                            <template v-else>
                                <b-form-group label="Biography" label-for="formrow-biography-input" class="mb-3">
                                    <b-form-textarea
                                            v-model.trim="biographyData.biography"
                                            id="biography"
                                            rows="6"
                                    ></b-form-textarea>
                                </b-form-group>
                                     <span style="font-weight: 500;" v-if="biographyData.ai_generated == '1'">AI Generated
                                       <i  class="uil uil-check font-size-25 text-success" style="font-size: 25px"></i>
                                     </span>
                                <b-button variant="primary" style="text-align: right" @click="saveBiographyOnly" class="mt-3">Save Biography</b-button>
                            </template>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>
        </form>
        <template v-slot:modal-footer>
            <div class="d-flex align-items-center" style="width: 100%;">
                <div v-if="activeTab === 'Player BIO'" style="text-align: right; flex: 1;">
                    <p class="mb-0 " style="text-align: left"><strong>Updated at:</strong> {{ formattedDate }}</p>

                    <!--<b-button variant="primary" @click="saveBiographyOnly">Save Biography</b-button>-->
                </div>
                <div v-else class="row" style="text-align: right;">
                    <div class="col-lg-6" style="flex: 1;">
                        <p class="mb-0"><strong>Updated at:</strong> {{ formattedDate }}</p>
                    </div>
                    <div class="col-lg-6">
                        <b-button variant="light" @click="closeModal" class="me-2">Close</b-button>
                        <b-button variant="primary" @click="editSquadPlayer" :disabled="tryingToEdit">
                            <b-spinner v-show="tryingToEdit" small></b-spinner>
                            Change
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </b-modal>
</template>